<script setup lang="ts">
withDefaults(
  defineProps<{
    errorMessages?: string | string[];
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    hideDetails?: boolean | string;
    placeholder?: string;
  }>(),
  {
    errorMessages: () => [],
    readonly: false,
    disabled: false,
    label: 'Password',
    hideDetails: 'auto',
    placeholder: '',
  }
);
const password = defineModel();

const showPassword = ref(false);
function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}
</script>

<template>
  <v-text-field
    v-model="password"
    flat
    variant="solo-filled"
    autocomplete="current-password"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :label="label"
    :readonly="readonly"
    :disabled="disabled"
    :type="showPassword ? 'text' : 'password'"
    :hide-detail="hideDetails"
    data-testid="password"
  >
    <template #append-inner>
      <slot name="append-inner">
        <v-icon data-testid="password-toggle" @click="togglePasswordVisibility">
          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </slot>
    </template>
  </v-text-field>
</template>

<style></style>
